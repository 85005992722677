header {
  width:100%;
  margin-bottom: 20px;
  display:flex;
  align-items:baseline;
  justify-content:space-evenly;
}
h1 {
  font-size: 45px;
  color: #efefef;
  width: 75%;
  padding-left: 15px;
}
.logged-in {
  margin-right: 10px;
}
.link {
  color: #efefef;
  text-decoration: none;
}
.link:hover {
  color: #efefef;
}
.login-container, .logged-in {
  display:flex;
  flex-direction: column;
  align-items:right;
}
.header-button {
  background-color: #AE4421;
  border-radius: 10px;
}
.header-button>a:link, .header-button>a:hover {
  color: #efefef;
  border-color: #313753;
}
.header-button>a:visited {
  color: #efefef;
}
@media all and (min-width: 750px) {
  h1 {
    width:max-content;
    font-size: 65px;
    margin:auto;
    padding: 10px 5px;
    
  }
  header {
    flex-direction: column;
    padding: 20px 0px;

  }
  .login-container, .logged-in {
    flex-direction: row;
    align-items:baseline;
    align-self: flex-end;
    padding: 10px;
    position: relative;
    right: 25px;
  }
  .header-button {
    margin: 0px 10px;
    height: max-content; 
  }
  .header-button>a {
    height:100%;
    font-size:20px;
    padding: 10px 16px;
  }
}