.display {
  background-color: #313753;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
  display: flex;
  color: #efefef;
  flex-direction: column;
  z-index:10;
  position:relative;
  top: -250px;
  width: 75%;
  display:flex;
  flex-direction: column;
}
button:hover {
  color: #AE4421;
  border-color: #AE4421;
}
#result {
  color: #efefef;
  font-size: 25px;
  width:100%;
  padding:10px;
}
.ingredients-list {
  padding:0 0 0 20px;
}
.close {
  width: 90%;
  margin:auto;
}
.result-button {
  margin-right: 10px;
  margin-bottom: 10px;
}
.details {
  align-self: flex-end;
}
#readout {
  padding: 10px;
}
@media all and (min-width: 900px) {
  .display {
    top: -300px;

  }
  .details {
    padding: 0px 5px;
  }
}