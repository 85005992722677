.login-form {
  width: 75%;
  height: 100;
  margin: auto;
  padding: 5px 10px;
  background-color: #313753;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
}
input {
  padding: 5px;
}
.sign-up {
  background-color: #434D77;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
  padding:2px;
  margin-top: 10px;
}
.sign-up>p {
  margin: 0;
  padding: 5px;
}
p>a {
  color: #efefef
}
.login-page {
  width:100%;
  height: 100%;
}
.login-heading, .register-heading {
  position: relative;
  top: -35px;
}
@media all and (min-width: 650px) {
  .login-form {
    height: fit-content;
  }
  /* .sign-up {
    position: relative;
    top: 50px;
  } */
  .login-heading, .register-heading {
    position:relative;
    top: -50px;
    margin: auto;
  }
 
}