.filters {
  width: 75%;
  height: auto;
  margin: auto;
  padding: 5px 10px;
  background-color: #313753;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
}
select {
  background-color: #1A1A2E;
  color: #efefef;
  border: none;
  padding: 2px
}
.filter-button {
  background-color: #16213E;
  color: #efefef;
  /* border: none;
  border-radius: 10px;
  padding: 5px 7px;
  box-shadow: 1px 2px 1px #121212;  */
}
.form-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}
fieldset {
  border: 0px;
}
.filter-heading {
  width:100%;
  padding: 0;
  margin: 0;
}
@media all and (min-width: 900px) {
  .filters {
    max-width:50%;
    margin: 0;
    margin-left:20px;
  }
}
