input {
  background-color: #1A1A2E;
  color: #efefef;
}
.red {
  color: red;
  font-weight: bold;
}
.new-thing-form {
  width: 75%;
  height: auto;
  margin: auto;
  padding: 5px 10px;
  background-color: #313753;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
}
.required {
  color:red;
  
}
input[type=text], input[type=number], select {
  padding: 5px;
}

.form-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}