.wheel-container {
  width:100%;
  height: fit-height;
  /* margin: 5px 0px;
  padding: 10px 0px; */
  overflow-y: hidden;
  overflow-x: hidden;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: space-evenly;
}

#wheel {
  min-width: 300px;
  min-height: 300px;
  width: auto;
  height:auto;
  
  overflow-y: hidden;
  overflow-x: hidden;
}

#spin, #reset {
  z-index: 5;
  font-size: 20px;
  padding: 10px 18px;
  margin: 15px 0;
  background-color: #AE4421;
  border-radius: 10px;
  box-shadow: 2px 3px 6px 3px #121212;
}

#spin:hover {
  color: rgb(0, 180, 120);
  border-color: rgb(0, 180, 120);
}

#spin:focus, #reset:focus {
  outline:none;
}

#reset:hover {
  color: red;
  border-color: red;
}

#selector {
  font-size: 40px;
  z-index: 3;
  color: #AE4421;
  text-shadow: 3px 3px #121212;
}


input {
  font-size: 16px;
  border:none;
  border-bottom: 1px solid black
}
/* #display {
  z-index:2;
  width:auto;
  height:auto;
} */
@media all and (min-width: 900px) {
  .wheel-container {
    margin: 0;
    padding: 0;
    height: 100%;
  }
}