.recipe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
}
.user-recipes {
  display: flex;
  flex-direction: column;
  
}