.popup {
  background-color: #313753;
  border-radius: 5px;
  box-shadow: 2px 3px 6px 3px #121212; 
  width: 85%;
  margin-left:auto;
  margin-right:auto;
  padding: 10px;
  z-index:8;
  position: absolute;
  left:0;
  right:0;

}