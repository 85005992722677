.details-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  width:80%;
  height:auto;
  margin:auto;
}
div>.details-container {
  align-items: flex-start;
}
.back-button {
  background-color: #AE4421;
  border-radius: 10px;
  box-shadow: 2px 3px 6px 3px #121212; 
}
.back-button:hover {
  color: #313753;
  border-color: #313753;
}
a:hover {
  color: #AE4421;
}
h3 a{
  color: #efefef;
  font-size: 35px;
}
.visit {
  font-size: 14px;
}
p {
  font-size: 20px;
}
h3 a:hover {
  color: #AE4421
}