#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
html, body {
  width: 100%;
  height: 100%;
  background: linear-gradient(#1a1a2e, #16213e, #1a1a2e); 

  color:#efefef;
}
main {
  width:100%;
  height:fit-content;
  display:flex;
  flex-direction: column;
}
nav {
  padding: 20px 0px;
}

nav>ul {
  margin: 0;
  padding: 0;
  display:flex;
  justify-content: space-around;
}
li {
  list-style-type: none;
}
.nav-link {
  text-decoration: none;
  color:#efefef;
}
.big-container {
  width:100%;
  height:100%;

}
@media all and (min-width: 750px) {
  body {
    font-size: 25px;
  }
  nav {
    padding: 0;
    padding-bottom:20px;
  }
}
@media all and (min-width:900px) {
  .big-container {
    display:flex;
    height: auto;
    justify-content: space-around;
    align-items: flex-start;

  }
  .filters {
    position: relative;
    top: 100px;
  }
  .wheel-container {
    max-width:50%;
  }
  .popup {
    margin: 0;
  }
}